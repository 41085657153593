import React from "react";
import { ILibrary, IPagination, ISearchCriteria } from "../../../../library/models";
import { LibraryList } from '../../../../../pages/library/list';
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../redux/slices/appInfo";
import { getTestLibrary } from "../../../../../api/library";
import { valueFormatter } from "../../../../../utils";
import { ICClockTime, ICStar, ICUserV2 } from "../../../../../icons";
import { TestFilter } from "./filter";
import { TestLibraryCard } from "./card";
import classNames from 'classnames'
import { useSticky } from "../../../../../hooks/useSticky";
import { useAssementData } from "../../hooks/useCreateAssessment";
import { MAX_TEST_QUESTION } from "../..";
import { SelectTests } from "./components/select-tests";
import i18next from "../../../../../i18n";

const fetchTestLibrary = async (params: any): Promise<{
    data: any,
    metaData: any
}> => {
    const results = await getTestLibrary(params);
    const { data } = results || {};
    const newData = data.data || [];
    newData.map((d: ILibrary) => {
        d.tags = [];
        const sumQuestionDuration = Array.isArray(d.configuration?.questionDuration) ? d.configuration?.questionDuration.reduce((a, b) => a + b, 0) : d.configuration?.questionDuration;
        d.tags.push(
            {
                name: `${sumQuestionDuration || 0}p`,
                icon: <ICClockTime width={12} height={12} stroke="#4F4B5C" />
            },
        );
        if (d?.totalParticipate) {
            d.tags.push(
                {
                    name: `${valueFormatter(d?.totalParticipate)}`,
                    icon: <ICUserV2 width={12} height={12} stroke="#4F4B5C" />
                }
            );
        }
        d.tags.push(
            {
                name: d?.avg_rate || '',
                icon: <ICStar />
            }
        );
        return { ...d };
    });
    return {
        data: newData,
        metaData: data.metaData
    }
}
export const AssessmentAddTest: React.FC = () => {
    const dispatch = useDispatch();
    const { isSticky, element } = useSticky();
    const { tests, setTests, assessment, ratingLevel } = useAssementData();

    const onAddTest = React.useCallback((test: ILibrary) => {
        setTests((prev) => {
            // Find index of undefined
            const index = prev.findIndex((t) => !t._id);
            if (index === -1) {
                return prev;
            }
            const newTests = [...prev];
            newTests[index] = { ...newTests[index], ...test };
            return newTests;
        });
    }, [setTests]);
    
    const [data, setData] = React.useState<ILibrary[]>([]);
    const [pagination, setPagination] = React.useState<IPagination>();
    const [filterParams, setFilterParams] = React.useState<ISearchCriteria>({
        page: 1,
        limit: 12,
        roles: assessment?.role ? [assessment?.role]: [],
        industry: undefined,
        language: undefined,
        planTag: undefined,
        statusTag: undefined,
    })
    const loadData = async (value: ISearchCriteria) => {
        const newParams = { ...filterParams, ...value };
        setFilterParams(newParams);
        dispatch(setLoading(true));
        try {
            const {
                data,
                metaData
            } = await fetchTestLibrary(newParams);
            setData(data);
            setPagination(metaData);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    React.useEffect(() => {
        loadData(filterParams);
    }, []);

    const onChangePage = React.useCallback((values: any) => {
        const newParams = { ...filterParams, ...values };
        loadData(newParams);
    }, [filterParams]);

    const onChangeData = React.useCallback((values: any) => {
        loadData({
            ...values,
            limit: 12,
            page: 1
        });
    }, [filterParams]);

    const filterValuesDefaults: ISearchCriteria = {
        page: 1,
        limit: 12,
        searchText: '',
        language: '',
        roles: assessment?.role ? [assessment?.role]: [],
        type: undefined,
        industry: [],
        statusTag: '',
        planTag: '',
        favorite: undefined,
        rating: undefined,
    };

    const onReloadData = React.useCallback(() => {
        loadData(filterValuesDefaults);
    }, [filterValuesDefaults]);

    const indexAvailable = React.useMemo(() => {
        return tests.findIndex(test => test._id === undefined);
    }, [tests])

    return <div className="space-y-12">
        <div className="bg-white p-[32px] flex flex-col gap-[24px] shadow-e-03 rounded-[12px] box-border overflow-hidden">
            <div className="flex flex-col gap-[8px]">
                <div className="text-primary-bold text-title-medium">{i18next.t('createAssessment.listTests')}</div>
                <div className="text-body text-med-em">{i18next.t('createAssessment.addTestFromBelow', {t: MAX_TEST_QUESTION})}</div>
            </div>
            <div ref={element}
                className={classNames("flex space-x-4", isSticky ? "question-test-sticky" : "")}>
                <SelectTests isSticky={isSticky} />
            </div>
        </div>
        <TestFilter
            filterParams={filterParams}
            onReloadData={onReloadData}
            onChange={onChangeData}
        />
        <LibraryList
            data={data}
            keyword={filterParams?.searchText}
            pagination={pagination}
            onChange={onChangePage}
            onReloadData={onReloadData}
            showResult={false}
            pagingMode="infinite"
            itemRender={(item: ILibrary, keyword: string) => {
                return <TestLibraryCard
                    onAddTest={onAddTest}
                    data={item}
                    keyword={keyword}
                    domIdRef={indexAvailable !== undefined ? `test_num_${indexAvailable}` : undefined}
                    isAdded={tests.some(test => test._id === item._id)}
                    allowAdd={tests.filter(test => test._id).length >= MAX_TEST_QUESTION}
                    ratingLevel={ratingLevel}
                    onRemove={() => {
                        setTests(prev => {
                            const newTest = prev.map(({ _id, ...others }) => {
                                if (_id === item?._id) return {
                                    ...others
                                }
                                return {
                                    _id,
                                    ...others
                                }
                            });
                            newTest.sort((a: any, b: any) => a._id ?
                                b._id ? 0 : -1
                                : b._id ? 1 : 0
                            );
                            return newTest;
                        })
                    }}
                />
            }}
        />
    </div>
}
