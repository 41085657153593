import React, { useState, useMemo, useEffect, useCallback } from "react"
import { ICSearch, ICFilter, ICClose, ICAlertCircle } from "../icons/index";
import { IconButton } from "./button"
import { HRInput } from "./form/input"
import { HRSelect } from "./form/select"
import { Tooltip } from "antd"
import debouce from 'lodash/debounce';
import { ISearchCriteria } from '../pages/library/models';
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { UserRoles } from "../redux/slices/userInfo";
import { firstValueCommonData } from "../redux/selectors/commonData";
import { useTranslation } from "react-i18next";
import { HRTooltip } from "./tooltip";
import i18next from "../i18n";
import { useMobile } from "../hooks/useDimensions";

const PLAN_TAG = [
    { label: i18next.t('filter.free'), value: 'free' },
    { label: i18next.t('filter.pay'), value: 'pay' }
];

const STATUS_TAG = [
    { label: i18next.t('filter.new'), value: 'new' },
    { label: i18next.t('filter.popular'), value: 'popular' },
];

type HRFilterProps = {
    onChange: (value: any) => void;
    filterParams: ISearchCriteria;
    removeAdditionalOptions?: boolean;
}

export const MY_COMPANY_TESTS = {
    label: "My Company Tests",
    value: "MY_COMPANY_TESTS",
    _id: "MY_COMPANY_TESTS"
}

export const HRFilter: React.FC<HRFilterProps> = ({
    onChange,
    filterParams,
    removeAdditionalOptions = false
}) => {
    const { i18n } = useTranslation();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const languageNameDefault = firstValueCommonData('language', i18n.language, 'label') || '';
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState<string | undefined>("");
    const handleChange = useCallback(($event: any) => {
        let value = $event.target.value;
        const MAX_LENGTH = 30;
        if (value.length > MAX_LENGTH) {
            return;
        }
        setTitle(value);
        const newParams = { ...filterParams, searchText: value, page: 1 };
        onSearchKeyword(newParams);
    }, [filterParams]);

    const onSearchKeyword = useMemo(() => {
        return debouce(onChange, 600);
    }, [onChange]);

    useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        }
    }, [onSearchKeyword]);

    useEffect(() => {
        if (filterParams) {
            setTitle(filterParams.searchText ?? '');
        }
    }, [filterParams]);
    const onChangeData = useCallback((values: any) => {
        onChange({ ...filterParams, ...values, page: 1 });
    }, [filterParams]);

    const onClearValue = React.useCallback(() => {
        setTitle(undefined);
        const newParams = { ...filterParams, searchText: undefined, page: 1 };
        onChange(newParams);
    }, [filterParams, onChange]);
    return (
        <div className="lg-container px-2 lg:px-0 mx-auto flex justify-between">
            <div className="grid grid-cols-1 gap-[30px] w-full">
                <div className="grid grid-cols-1 lg:grid-cols-[1fr_40px] gap-[16px] w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-[16px]">
                        <div className="lg:pt-[24px]">
                            <HRInput
                                placeholder={i18next.t('filter.search') ?? ''}
                                hasClearIcon={true}
                                onClearValue={onClearValue}
                                onChange={handleChange}
                                value={title}
                                prefix={<ICSearch />}
                            />
                        </div>
                        <HRSelect
                            name="language"
                            label={i18next.t('filter.language') ?? ''}
                            placeholder={languageNameDefault}
                            value={filterParams.language ?? null}
                            className="w-full"
                            onChange={(value) => onChangeData({ language: value })}
                        />
                        <HRSelect
                            name="position"
                            mode="multiple"
                            label={i18next.t('filter.position') ?? ''}
                            value={filterParams.roles ?? []}
                            placeholder={i18next.t('filter.select')}
                            className="w-full"
                            onChange={(value) => onChangeData({ roles: value })}
                        />
                        <HRSelect
                            name="test_type"
                            label={i18next.t('filter.type') ?? ''}
                            value={filterParams.type ?? null}
                            placeholder={i18next.t('filter.select')}
                            className="w-full"
                            onChange={(value) => onChangeData({ type: value })}
                            additionalOptions={
                                removeAdditionalOptions || userInfo.role === UserRoles.SPONSOR
                                    ? undefined
                                    : [MY_COMPANY_TESTS]
                            }
                        />
                        <div className="grid lg:block grid-cols-[1fr_40px] gap-2">
                            <HRSelect
                                name="test_type"
                                label={i18next.t('filter.type') ?? ''}
                                value={filterParams.type ?? null}
                                placeholder={i18next.t('filter.select')}
                                className="w-full"
                                onChange={(value) => onChangeData({ type: value })}
                                additionalOptions={
                                    removeAdditionalOptions || userInfo.role === UserRoles.SPONSOR
                                        ? undefined
                                        : [MY_COMPANY_TESTS]
                                }
                            />
                            <div className="block lg:hidden pt-[28px]">
                                <HRTooltip
                                    placement="bottomRight"
                                    content={i18next.t('filter.advancedFilter')}
                                    hasInfoIcon={true}
                                    childNode={(
                                        <IconButton
                                            btnType="sub"
                                            btnSize="md"
                                            icon={!open ? <ICFilter /> : <ICClose height={18} width={18} />}
                                            onClick={() => setOpen(!open)} />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:grid grid-cols-1 items-end justify-end">
                        <div className="pt-[24px]">
                            <HRTooltip
                                placement="bottomRight"
                                content={i18next.t('filter.advancedFilter')}
                                hasInfoIcon={true}
                                childNode={(
                                    <IconButton
                                        btnType="sub"
                                        btnSize="md"
                                        icon={!open ? <ICFilter /> : <ICClose height={18} width={18} />}
                                        onClick={() => setOpen(!open)} />
                                )}
                            />
                        </div>
                    </div>
                </div>

                {open && <div className="grid grid-cols-1 lg:grid-cols-[1fr_40px] gap-[16px] w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-[16px] item-end w-full">
                        <div className="hidden lg:grid grid-cols-1"></div>
                        <HRSelect
                            mode="multiple"
                            name="test_field"
                            label={i18next.t('filter.industry') ?? ''}
                            placeholder={i18next.t('filter.select')}
                            className="w-full"
                            value={filterParams.industry ?? []}
                            onChange={(value) => onChangeData({ industry: value })}
                        />
                        <HRSelect
                            options={PLAN_TAG}
                            hasSearch={false}
                            label={i18next.t('filter.label') ?? ''}
                            placeholder={i18next.t('filter.select')}
                            className="w-full"
                            value={filterParams.planTag ?? []}
                            onChange={(value) => onChangeData({ planTag: value })}
                        />
                        <HRSelect
                            options={STATUS_TAG}
                            label={i18next.t('filter.group') ?? ''}
                            hasSearch={false}
                            placeholder={i18next.t('filter.select')}
                            className="w-full"
                            value={filterParams.statusTag ?? []}
                            onChange={(value) => onChangeData({ statusTag: value })}
                        />
                    </div>
                    <div className="grid grid-cols-1">
                    </div>
                </div>}

            </div>

        </div>
    )
}
