import React from "react";
import { TStepItemProps, STEP, DEFAULT_ADD_TESTS } from "./config";
import { CreateAssessmentHeader } from "./header";
import { AssementDataContext } from "./hooks/useCreateAssessment"
import { CreateAssessmentStep } from "./step";
import { BlankAssessment, TAdditionalQuestion, TAssessment, TReviewQuestion } from "./type";
import { useForm } from 'antd/lib/form/Form';
import classNames from "classnames"
import { AddQuestionAssessment } from "./components/add-additional-test/components/add";
import { ILibrary, TRatingLevel } from "../../library/models";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/appInfo";
import { getAssessmentDetails, getMyAssessment } from "../../../api/assessment";
import { TAssessmentData } from "../type";
import { useGeneralAssessment } from "./hooks/useAssessment";
import { useParams } from "react-router-dom";
import { usePrompt } from "../../../hooks/usePromt";
import _ from "lodash";
import { EQuestionType, TChoiceQuestionType, generateOption } from "../../create_test/tab/question/type";
import { useNotification } from "../../../hooks/useNotification";
import { getTestRatingLevel } from "../../../api/library";
import i18next from "../../../i18n";
import { CreateDimensionFooter } from "./footer";
export const MAX_TEST_QUESTION = 5;
export const MAX_ADDITIONAL_QUESTION = 10;

type TCreateAssessmentPage = {
    mode?: 'create' | 'edit'
};

export const CreateAssessmentPage: React.FC<TCreateAssessmentPage> = ({
    mode = 'create'
}) => {
    const dispatch = useDispatch();
    const { showError } = useNotification();
    const [step, setStep] = React.useState<number>(0);
    const { id = '' } = useParams();
    const [isPrompt, setIsPrompt] = React.useState(true);
    const [myAssessment, setMyAssessment] = React.useState<TAssessmentData[]>([]);
    const [stepAssessment, setStepAssessment] = React.useState<TStepItemProps[]>(STEP);
    const [tests, setTests] = React.useState<ILibrary[]>(() => DEFAULT_ADD_TESTS);
    const [assessmentDetails, setAssessmentDetails] = React.useState<TAssessment>(BlankAssessment);
    const [assessment, setAssessment] = React.useState<TAssessment>(BlankAssessment);
    const [generalForm] = useForm();

    const { saveGeneralInformation } = useGeneralAssessment(generalForm, setStep, setAssessment);

    const [showAddQuestion, setShowAddQuestion] = React.useState(false);
    const [selectedQuestion, setSelectedQuestion] = React.useState<TAdditionalQuestion | undefined>(undefined);
    const [selectedQuestionType, setSelectedQuestionType] = React.useState<TChoiceQuestionType>(EQuestionType.SINGLE_CHOICE);
    const [additionalQuestions, setAdditionalQuestions] = React.useState<TAdditionalQuestion[]>([]);
    const [reviewQuestion, setReviewQuestion] = React.useState<TReviewQuestion[]>([]);

    const [ratingLevel, setRatingLevel] = React.useState<TRatingLevel[] | undefined>([]);

    const loadMyAssessment = async () => {
        const newParams = {
            page: 1,
            limit: 100,
        };
        dispatch(setLoading(true));
        try {
            const results = await getMyAssessment(newParams);
            const { data } = results || {};
            const newData = data.data || [];
            setMyAssessment(newData);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    const loadRatingLevel = React.useCallback(async () => {
        try {
            const res = await getTestRatingLevel();
            if (res.data?.length > 0) {
                setRatingLevel(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    React.useEffect(() => {
        loadRatingLevel();
        loadMyAssessment();
    }, []);

    const onAddQuestion = React.useCallback((
        show: boolean,
        type?: TChoiceQuestionType,
        indexQuestionSelected?: number
    ) => {
        if (!show) {
            setSelectedQuestion(undefined);
        }
        setShowAddQuestion(show);
        type && setSelectedQuestionType(type);
        if (show && indexQuestionSelected !== undefined) {
            setSelectedQuestion(additionalQuestions[indexQuestionSelected]);
        }
    }, [additionalQuestions])

    const onChangeStep = React.useCallback((step: number) => {
        generalForm.validateFields().then((results: any) => {
            if (mode === 'create') {
                saveGeneralInformation(results);
            } else {
                setStep(step);
            }
        }).catch((err) => {
            console.warn(err);
            showError(i18next.t("createAssessment.missRequiredInfomation"), i18next.t("createAssessment.refillRequiredInfomation"));
        });
    }, [mode, generalForm, saveGeneralInformation, showError]);

    const onCreatedQuestion = React.useCallback((question: TAdditionalQuestion) => {
        if (selectedQuestion) {
            const index = additionalQuestions.findIndex(item => item.id === selectedQuestion.id);
            if (index !== -1) {
                const newQuestions = [...additionalQuestions];
                newQuestions[index] = question;
                setAdditionalQuestions(newQuestions);
            }
        } else {
            setAdditionalQuestions([...additionalQuestions, question]);
        }
        onAddQuestion(false);
    }, [additionalQuestions, onAddQuestion, selectedQuestion]);

    React.useEffect(() => {
        if (mode !== "edit" || !id) return;
        try {
            dispatch(setLoading(true));
            getAssessmentDetails(id).then((res) => {
                Object.keys(generalForm.getFieldsValue()).forEach(key => {
                    generalForm.setFieldsValue({
                        [key]: res.data[key] || ''
                    })
                });
                setAssessment(res.data);
                setAssessmentDetails(res.data);
                if (res.data?.tests) {
                    const tests = DEFAULT_ADD_TESTS.map((e, i) => {
                        if (res.data.tests[i]) return res.data.tests[i]
                        return e;
                    });
                    setTests(tests);
                }
                if (res.data?.additionalQuestions) {
                    setAdditionalQuestions(res.data.additionalQuestions.map((q: any) => ({
                        ...q,
                        options: q.options.map((o: string) => generateOption(o))
                    })));
                }
            })
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
         finally {
            dispatch(setLoading(false));
        }

    }, [mode, dispatch, generalForm, id, showError]);

    // check data change
    const pageIsDirty = React.useMemo(() => {
        if (mode !== "edit" || !id || !isPrompt) return false;
        const generalFormIsDirty = assessmentDetails.title !== assessment.title ||
            assessmentDetails.role !== assessment.role
            || assessmentDetails.language !== assessment.language
            || assessmentDetails.introduce !== assessment.introduce;
        let realTests = tests.filter(t => t._id);
        const testsIsDirty = realTests.length === assessmentDetails.tests?.length ? tests.some((test, index) => {
            const res = !_.isEqual(test._id, assessmentDetails.tests && assessmentDetails.tests && assessmentDetails.tests[index]?._id);
            return res;
        }) : true;
        const additionalQuestionsIsDirty = additionalQuestions.length === assessmentDetails?.additionalQuestions.length ? additionalQuestions.some((question, index) => {
            return question._id !== assessmentDetails?.additionalQuestions[index]?._id;
        }) : true;
        return generalFormIsDirty || testsIsDirty || additionalQuestionsIsDirty;
    }, [isPrompt, assessmentDetails, assessment, tests, mode, id, additionalQuestions]);

    usePrompt('Changes you made may not be saved.?', pageIsDirty);
    React.useEffect(() => {
        const handler = (e: any) => {
            e.preventDefault();
            if (!pageIsDirty) {
                return e.returnValue = undefined;
            }
            return e.returnValue = true;
        };
        if (!pageIsDirty) {
            window.removeEventListener("beforeunload", handler);
            return;
        }

        window.addEventListener("beforeunload", handler);
        return () => {
            window.removeEventListener("beforeunload", handler);
        }
    }, [pageIsDirty]);

    return <AssementDataContext.Provider
        value={{
            step,
            setStep,
            onChangeStep,
            tests,
            setTests,

            myAssessment,
            setMyAssessment,

            assessment,
            setAssessment,
            generalForm,
            stepAssessment,

            showAddQuestion,
            setShowAddQuestion: onAddQuestion,
            selectedQuestion,
            selectedQuestionType,
            setSelectedQuestionType,
            onCreatedQuestion,

            additionalQuestions,
            setAdditionalQuestions,
            reviewQuestion,
            setReviewQuestion,

            isPrompt,
            setIsPrompt,
            ratingLevel,
        }}
    >
        <div className="lg-container px-2 lg:px-0 lg:mx-auto pt-4 lg:pt-[40px]">
            {
                showAddQuestion && <AddQuestionAssessment />
            }
            <div className={classNames("grid grid-cols-1 lg:gap-8 lg:gap-[40px]", showAddQuestion ? "hidden" : "")}>
                <CreateAssessmentHeader mode={mode} id={id} />
                <CreateAssessmentStep />
                <div className="my-[6px] rounded-[8px] min-h-[211px]">
                    {
                        STEP[step].component
                    }
                </div>
                <CreateDimensionFooter mode={mode} id={id} />
            </div>
        </div>
    </AssementDataContext.Provider>
}
